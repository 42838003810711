<template>
  <div class="main">

    <div class="container">
      <p style="margin-top:10px">
        <router-link class="position" :to="{ name: 'index', params: { index: 0 } }">首页 </router-link>
        <span class="position">&gt;&gt; 下载中心</span>

      </p>
      <div class="newscontainer">
        <div class="newscontainerL">
          <div class="newscontainerL1">下载中心</div>
          <div v-for="(item1, index) in typelist" :key="item1.type">
            <div class="newscontainerL2" @click="menushow(item1)"> {{ item1.name }}</div>
            <div class="newscontainerL3" v-if="item1.show" v-for="(item2, index) in item1.children" :key="item2.type"
              @click="menushow(item2)">
              <!-- <router-link to="/download/pselectfork" class="newscontainerL3" >{{ item2.name }}</router-link>               -->
              {{ item2.name }}
            </div>
          </div>
        </div>
        <div class="newscontainerR">
          <div @click="todownload(item.title)" class="newitem" v-for="(item, index) in showlist" :key=index>
            <p class="newstitle">{{ item.title }}</p>
            <p class="newstime">{{ item.time }}</p>
          </div>
          <router-view />
        </div>
      </div>
    </div>
    <el-dialog title="资料下载" :visible.sync="dialogFormVisible">
      <el-form :model="form" :rules="rules" label-width="100px" ref="form">
        <el-form-item label="单位名称" prop="unit_name">
          <el-input v-model="form.unit_name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="姓名" prop="name">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="职位" prop="position">
          <el-input v-model="form.position" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="联系手机" prop="phone">
          <el-input v-model="form.phone" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="联系邮箱" prop="email">
          <el-input v-model="form.email" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('form')">下 载</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

export default {
  name: 'download',
  components: {
  },
  data() {
    var checkphone = (rule, value, callback) => {
      if (!this.isPhone(value)) {//引入methods中封装的检查手机格式的方法
        callback(new Error());
      } else {
        callback();
      }
    };
    var checkemail = (rule, value, callback) => {
      if (!this.isEmail(value)) {//引入methods中封装的检查手机格式的方法
        callback(new Error());
      } else {
        callback();
      }
    };
    return {

      rules: {
        unit_name: [
          { required: true, message: '请输入公司名称', trigger: 'blur' },
        ],
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
        ],
        position: [
          { required: true, message: '请输入职位', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '请输入电话', trigger: 'blur' },
          { validator: checkphone, trigger: 'blur', message: '请输入正确的电话' }
        ],
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          { validator: checkemail, trigger: 'blur', message: '请输入正确的邮箱' }
        ]
      },
      form: {
        download_code: '',
        unit_name: '',
        name: '',
        phone: '',
        position: '',
        email: ''
      },
      dialogFormVisible: false,
      typelist: [
        {
          type: 0,
          name: '选型手册',
          show: true
        },
        {
          type: 1,
          name: '堆垛机系列',
          show: false,
          children: [
            {
              type: 1.1,
              name: '单立柱系列',
            },
            {
              type: 1.2,
              name: '双立柱系列',
            },
          ]
        },
        {
          type: 2,
          name: '货叉系列',
          show: false,
          children: [
            {
              type: 2.1,
              name: '板叉系列',
            },
            {
              type: 2.2,
              name: '指叉系列',
            },
          ]
        },
      ],
      list: [
        {
          type: 2.1,
          title: '板叉850x420x1070-150（伸出状态）.x_t',
        },
        {
          type: 2.1,
          title: '板叉1000x300x1270-150(伸出）.x_t',
        },
        {
          type: 2.1,
          title: '板叉1200x380x1480-400（伸出）.x_t',
        },
        {
          type: 2.1,
          title: '板叉1300x440x1650-300（伸出）.x_t',
        },
        {
          type: 2.2,
          title: '单伸指叉150kg(伸叉).x_t',
        },
        {
          type: 2.2,
          title: '单伸指叉400kg(伸叉).x_t',
        },
        {
          type: 2.1,
          title: '双伸货叉150kg(单伸).x_t',
        },
        {
          type: 2.2,
          title: '双伸货叉150kg(双伸).x_t',
        },
        {
          type: 2.1,
          title: '双伸货叉1200x350x2570-150（单伸）.x_t',
        },
        {
          type: 2.2,
          title: '双伸货叉1200x350x2570-150（双伸）.x_t',
        },
        {
          type: 0,
          title: '深圳市欣畅达科技有限公司产品选型手册.pdf',
        }
      ],
      showlist: []
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.axios.post('http://api.szxcd.com.cn/user/add', this.form).then(res => {
            if (res.data.status == 200) {
              window.open('http://www.szxcd.com.cn/content/' + this.form.download_code)
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    todownload(title) {
      this.dialogFormVisible = true;
      this.form.download_code = title;
    },
    isPhone(val) {
      if (!/^1([358][0-9]|4[579]|66|7[0135678]|9[89])[0-9]{8}$/.test(val)) {
        return false;
      } else {
        return true;
      }
    },
    isEmail(val) {
      if (!/[a-zA-Z0-9]+([-_.][A-Za-zd]+)*@([a-zA-Z0-9]+[-.])+[A-Za-zd]{2,5}$/.test(val)) {
        return false;
      } else {
        return true;
      }
    },
    menushow(val) {

      if (val.children && val.children.length > 0) {

        for (let index = 0; index < this.typelist.length; index++) {
          const element = this.typelist[index];
          element.show = false;
        }

        val.show = !val.show;
      }

      this.showlist = [];
      for (let index = 0; index < this.list.length; index++) {
        const element = this.list[index];
        if (element.type === val.type) {
          this.showlist.push(element);
        }
      }
    }
  },
  watch: {

  },
  created() {
    window.scrollTo(0, 0)
  },
}
</script>

<style scoped>
.newstitle {
  width: 700px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left !important;
}

.newstime {
  width: 100;
  text-align: right;
}

::v-deep .el-pagination .btn-next {
  padding: 0 10px;
}

::v-deep .el-pagination .btn-prev {
  padding: 0 10px;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #fad749;
}

.pageclass {
  width: 910px;
  margin-left: 290px;
  text-align: center;
  margin-top: 60px;
  margin-bottom: 60px;
}

.newitem img {
  width: 210px;
  height: 210px;
  object-fit: cover;
}

.newitem p {
  line-height: 43px;
  font-size: 16px;
  color: #444;
  text-align: center;
}

.newitem:hover .newstitle {
  color: #fad749;
  transition: all .5s;
}

.newitem {
  font-size: 0;
  height: 50px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  transition: all .5s;
  display: flex;
  justify-content: space-between;
}

.newscontainerL2 {
  cursor: pointer;
  line-height: 50px;
  text-align: center;
  color: #fff;
  font-size: 18px;
  /* background-color: #70b9ec; */
  background-image: url('http://www.szxcd.com.cn/content/img/button.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-bottom: 1px;
}

.newscontainerL3 {
  cursor: pointer;
  line-height: 50px;
  text-align: center;
  color: rgb(0, 0, 0);
  font-size: 18px;
  /* background-color: #70b9ec; */
  /* background-image: url('http://www.szxcd.com.cn/content/img/button.png');
  background-size: 100% 100%;
  background-repeat: no-repeat; */
  margin-bottom: 1px;
}

.newscontainerL1 {
  line-height: 80px;
  font-size: 30px;
  background-color: #154ec3;
  color: #fff;
  text-align: center;
  margin-bottom: 20px;
}

.newscontainerL {
  width: 270px;
}

.newscontainerR {
  width: 900px;
  /* height: 800px; */
  margin-bottom: 20px;
}

.newscontainer {
  display: flex;
  margin-top: 34px;
  justify-content: space-between;
  flex-wrap: wrap;
}

a {
  text-decoration: none;
  font-size: 14px;
  color: #666;
}

.carouselItem {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.active {
  background-color: #ffd800;
}

.position {
  font-size: 14px;
  color: #666;
}
</style>
